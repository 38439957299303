import React from "react";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import Select from "react-select";
import { decryptData } from "../../utils";
import TimePicker from "react-time-picker";
import { addCategory, addProduct, addRestaurant, updateUser } from "../../firebase";
import { restaurantModel } from "../../model";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { roles, categoryType } from "../../formSource";
import Inputs from "./Inputs";
import { get, post } from "../../service/api";
import { withTranslation } from 'react-i18next';

const customStyles = {
  control: (base) => ({
    ...base,
    border: 0,
    width: "100%",
    borderBottom: "1px solid gray",
  }),
};
class FormInput extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      disabled: false,
      inputs: {},
      button: {
        text: "Send",
        color: "teal",
      },
      categories: []
    };
  }

  componentDidMount() {
    // Perform any actions you need when the component mounts
    this.setState({
      inputs: {
        ...this.props.data,
        role: roles.find((role) => role.value == this.props?.data?.role)
      },
    });
    if (this.props.type == 'products') {
      get('/categories/get').then((res) => this.setState({ categories: res.data.data.map(item => ({ label: item.name, value: item.id })) }))

    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.inputs, this.props.data);
    console.log('OOOOOOOOOOOOOOOOO', this.props.inputs, this.props.type, this.props.type === 'categorie');
    let url = ''
    if (this.props.data && this.props.inputs && this.props.type === 'users') url = '/users/update'
    //  updateUser({ ...this.props.data, ...this.state.inputs }, this.props.itemId).then(() =>
    //   this.props.navigate("/" + this.props.type)
    // ).catch((e) => {
    //   console.log('EEEEEEEEEEEEEEEE', e)
    // })
    else if (this.props.inputs && this.props.type === 'users') url = '/users/create'
    else if (this.props.inputs && this.props.type === 'categories') url = '/categories/create'
    else if (this.state.inputs.image && this.props.type === "restaurants") {
      addRestaurant(this.state.inputs).then(() =>
        this.props.navigate("/" + this.props.type)
      );
    } else if (this.state.inputs && this.state.inputs.image && this.props.type === "products") {

      url = '/products/create'
      // addProduct(this.state.inputs).then(() =>
      //   this.props.navigate("/" + this.props.type)
      // );
    }
    else if (this.state.inputs.image) {
      this.setState({ disabled: true });
      addCategory(this.state.inputs).then(() =>
        this.props.navigate("/" + this.props.type)
      );
    }
    console.log('EEEEEEEEEEEEEEEEEEEEEEE', url);
    if (url) {
      const formData = new FormData();
      // Append other form data fields
      Object.entries({ ...this.props.data, ...this.state.inputs }).forEach(([key, value]) => {
        formData.append(key, value);
      });
      post(url, formData, {
        'content-type': 'multipart/form-data'
      }).then(() =>
        this.props.navigate("/" + this.props.type)
      ).catch((e) => {
        console.log('EEEEEEEEEEEEEEEE', e)
      })
    }
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    this.setState({
      inputs: {
        ...this.state.inputs,
        [name]: target.value,
      },
      button: {
        text: "Send",
        color: "teal",
      },
    });
  };
  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="formInput">
          <label htmlFor="file">
            {this.props.type === "drivers" ? "Car Image" : "Image"}:{" "}
            <DriveFolderUploadOutlinedIcon className="icon" />
          </label>
          <input
            type="file"
            id="file"
            onChange={async (e) => {
              const file = e.target.files[0];
              // const storage = getStorage();
              // const storageRef = ref(storage, file.name);
              // await uploadBytes(storageRef, file);
              // const url = await getDownloadURL(storageRef);
              const reader = new FileReader();
              reader.onload = () => {
                this.props.setFile(reader.result);
                this.setState({
                  inputs: {
                    ...this.state.inputs,
                    image: file,
                  },
                });
              };
              reader.readAsDataURL(file);
              // this.setState({
              //   inputs: {
              //     ...this.state.inputs,
              //     image: url,
              //   },
              // });
              // this.props.setFile(url);
            }}
            style={{ display: "none" }}
            required
          />
        </div>
        {this.props.inputs.map((input) => {
          return (
            <>
              {!(input.alias === "password" && this.props.itemId) && <div className="formInput" key={input.id}>
                <label>{this.props.t(input.label)}</label>
                <input
                  type={input.type}
                  placeholder={input.placeholder}
                  name={input.alias}
                  required="required"
                  onChange={this.handleInputChange}
                  defaultValue={
                    this.props.data
                      ? this.props.data[input.alias]
                      : input.placeholder
                  }
                />
              </div>}
            </>
          );
        })}

        {this.props.type === "products" && (
          <div className="formInput">
            <label>Category</label>
            <Select
              styles={customStyles}
              options={this.state.categories}
              placeholder={"Select Type"}
              value={this.state.categories.find(category => category.value == this.props?.data?.categoryId)}
              onChange={(e) =>
                this.setState({
                  inputs: {
                    ...this.state.inputs,
                    categoryId: e.value,
                  },
                })
              }
            />
          </div>
        )}

        {/* {this.props.type === "users" && (
          <div className="formInput">
            <label>Role</label>
            <Select
              styles={customStyles}
              options={roles}
              value={roles.find((item) => item.value === this.props?.data?.role)}
              onChange={(e) =>
                this.setState({
                  inputs: {
                    ...this.state.inputs,
                    role: e.value,
                  },
                })
              }
            />
          </div>
        )} */}
        {this.props.type === "restaurant" && (
          <>
            <div className="formInput">
              <label>Reward</label>
              <input type="text" />
            </div>
            <div className="formInput">
              <label>Collect Time</label>
              <input type="text" />
            </div>
            <div className="tmpContainer">
              <label>Opening Time</label>
              <TimePicker className="tmp" />
            </div>
            <div className="tmpContainer">
              <label>Closing Time</label>
              <TimePicker className="tmp" />
            </div>
          </>
        )}
        <button
          disabled={this.state.disabled}
          className="button"
          style={{ backgroundColor: this.state.button.color }}
        >
          {this.state.button.text}
        </button>
      </form>
    );
  }
}
export default  withTranslation()(FormInput);
