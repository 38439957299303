import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";

import TimePicker from "react-time-picker";

import { Button, Modal, Space } from "antd";

import Select from "react-select";
import { useParams } from "react-router-dom";
import { decryptData, getData } from "../../utils";
import FormInput from "./FormInput";
import { useNavigate } from "react-router-dom";
import { DotLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

const New = ({ inputs, title, type }) => {
  const navigate = useNavigate();
  const { t } = useTranslation()

  const paramName = Object.keys(useParams())[0];
  const itemId = useParams()[paramName];

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [file, setFile] = useState();

  const [value1, onChange1] = useState("");
  const [value2, onChange2] = useState("");
  const [role, setRole] = useState("");
  const [formData, setFormData] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      ...formData,
      file: URL.createObjectURL(file),
    });
  };

  const warning = () => {
    Modal.warning({
      title: "This is a demo version",
      content: "Modification is not allowed",
    });
  };

  useEffect(() => {
    if (itemId) {
      setIsLoading(true)
      getData(type, itemId).then((result) => {
        const value = result?.data?.data
        if (value) {
          setData(
            value
          );
          if (value.image)
            setFile(value.image)
        }
        setIsLoading(false)
      });
    } else {
      setData(null)
      setFile(null)
    }
  }, []);

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        {isLoading ?
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <DotLoader color={"#36D7B7"} loading={true} size={150} />
          </div>
          :
          <>
            <div className="top">
              <h1>{t(title)}</h1>
            </div>
            <div className="bottom">
              <div className="left" >
                <label htmlFor="file">
                  <img
                    src={
                      file
                        ? file
                        : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                    }
                  />
                </label>
              </div>
              <div className="right">
                <FormInput
                  inputs={inputs}
                  type={type}
                  data={data}
                  itemId={itemId}
                  navigate={navigate}
                  setFile={setFile}
                />
              </div>
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default New;
