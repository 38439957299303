import { getCategories } from "./firebase";

export const userInputs = [
    {
      id: 2,
      label: "Name",
      type: "text",
      placeholder: "John Doe",
      alias: 'name'
    },
    {
      id: 3,
      label: "Email",
      type: "mail",
      placeholder: "john_doe@gmail.com",
      alias: 'email'
    },
    {
      id: 4,
      label: "Phone",
      type: "text",
      placeholder: "+1 234 567 89",
      alias: 'phone'
    },
    {
      id: 5,
      label: "Password",
      type: "password",
      alias: 'password'
    },
    {
      id: 6,
      label: "Address",
      type: "text",
      placeholder: "Elton St. 216 NewYork",
      alias: "address"
    },
    {
      id: 7,
      label: "Country",
      type: "text",
      placeholder: "USA",
      alias: "country"
    },
  ];
  export const productInputs = [
    {
      id: 1,
      label: "Name",
      type: "text",
      alias: "name"
    },
    {
      id: 2,
      label: "Description",
      type: "text",
     alias: "description"
    },
    {
      id: 3,
      label: "Ingredients",
      type: "text",
      alias: "ingredients"
    },
    {
      id: 4,
      label: "Price",
      type: "text",
      placeholder: "100",
      alias: "price"
    },
    {
      id: 5,
      label: "Offer",
      type: "text",
      alias: "offer"
    },
  ];
  export const restaurantInputs = [
    {
      id: 1,
      label: "Name",
      type: "text",
      alias: "name"
    },
    {
      id: 2,
      label: "Description",
      type: "text",
     alias: "description"
    },
    {
      id: 3,
      label: "Phone",
      type: "text",
      alias: "phone"
    },
    {
      id: 4,
      label: "Latitude",
      type: "text",
     alias: "latitude"
    },
    {
      id: 5,
      label: "Longitude",
      type: "text",
     alias: "longitude"
    },
    {
      id: 6,
      label: "Address",
      type: "text",
     alias: "address"
    },
    {
      id: 7,
      label: "City",
      type: "text",
     alias: "city"
    },
    {
      id: 8,
      label: "Country",
      type: "text",
     alias: "country"
    },
  ];
  export const categoryInputs = [
    {
      id: 1,
      label: "Name",
      type: "text",
      alias: "name"
    },
    {
      id: 2,
      label: "Description",
      type: "text",
     alias: "description"
    },
  ];
  export const categoryFoodInputs = [
    {
      id: 1,
      label: "Name",
      type: "text",
      alias: "name"
    },
    {
      id: 2,
      label: "Description",
      type: "text",
     alias: "description"
    },
  ];
  export const carInputs = [
    {
      id: 1,
      label: "Car Name",
      type: "text",
    },
    {
      id: 2,
      label: "Car Number",
      type: "text",
    },
  ];

  export const roles = [
    {
      value: "Customer",
      label: "Customer"
    },
    {
      value: "Driver",
      label: "Driver"
    },
    {
      value: "Manager",
      label: "Manager"
    },
    {
      value: "Admin",
      label: "Admin"
    }
  ]

  export const categoryType = [
    {
      value: "food",
      label: "Food"
    },
    {
      value: "restaurant",
      label: "Restaurant"
    },
  ]
  export const categoryTypes = async ()=>{
    const categories = await getCategories().then(category => category );
    let categoryTypes = []
    for (let i = 0; i < categories.length; i++) {
      const element = categories[i];
      categoryTypes.push({
        value: element.id,
        label: element.name
      })
    }
    return categoryTypes
  }
  