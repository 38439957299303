import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import Table from "../../components/table/Table";
import List from "../../components/table/Table";
import { RestaurantContext } from "../../context/RestaurantContext";
import { useContext, useEffect, useState } from "react";
import Barchart from "../../components/barChart/Barchart";
import PieChartt from "../../components/pieChart/PieChart";
import { get } from "../../service/api";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { currentRestaurant } = useContext(RestaurantContext)
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    get('/users/dashboard').then((result) => {
      setData(result.data.data)
    })
  }, []);

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          {currentRestaurant ? <Widget type="confirmed-order" /> : <Widget type="user" content={data?.users ? data.users : 0} />}
          {currentRestaurant ? <Widget type="cooking-order" /> : <Widget type="order"  content={data?.todayOrders ? data.todayOrders : 0} />}
          <Widget type="earning"  content={data?.todayEarn ? data.todayEarn : 0} />
          {/* {currentRestaurant ? <Widget type="ready-for-pickup-order" /> : <Widget type="driver"   content={data?.users ? data.users : 0} />} */}
        </div>
        <div className="charts">
          <Featured />
          <Barchart data={data?.graphData? data.graphData: []} />
        </div>
        <div className="listContainer">
          <div className="listTitle">{t("Latest Transactions")}</div>
          <List />
        </div>
      </div>
    </div>
  );
};
export default Home;