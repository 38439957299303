import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CategoryIcon from "@mui/icons-material/Category";
import StoreIcon from "@mui/icons-material/Store";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link, useNavigate, useParams } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { RestaurantContext } from "../../context/RestaurantContext";
import { useContext, useState } from "react";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import PaymentsIcon from "@mui/icons-material/Payments";
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { Button, Menu } from "antd";
import { SelectedKeyContext } from "../../context/SelectedKey";
import { AuthContext } from "../../context/Auth";
import { useTranslation } from "react-i18next";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const Sidebar = ({ type }) => {
  console.log("type : ", "/" + type);
  const { currentRestaurant } = useContext(RestaurantContext);
  const { setCurrentUser } = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation()
  const { selectedKey, setSelectedKey } = useContext(SelectedKeyContext);
  const signOutUser = () => {
    localStorage.clear();
    setCurrentUser(null)
    navigate('/')
    // signOut(auth)
    //   .then(() => {
    //   })
    //   .catch((err) => console.log(err.code));
  };
  const items = [
    getItem(t('Dashboard'), "/", <DashboardIcon />),
    getItem(t("Users"), "sub", <PersonOutlineIcon className="icon" />, [
      getItem(t("Users List"), "/users"),
      getItem(t("Add User"), "/users/new"),
    ]),
    getItem(t("Menus"), "sub0", <StoreIcon className="icon" />, [
      getItem(t("Menus List"), "/products"),
    ]),
    getItem(t("Orders"), "sub1", <CreditCardIcon className="icon" />, [
      getItem(t("Orders List"), "/orders"),
    ]),
    // getItem("Drivers", "sub2", <LocalShippingIcon className="icon" />, [
    //   getItem("Drivers List", "/drivers"),
    //   getItem("Add Driver", "/drivers/new"),
    // ]),
    // getItem("Restaurants", "sub3", <RestaurantIcon className="icon" />, [
    //   getItem("Restaurants List", "/restaurants"),
    //   getItem("Add Restaurant", "/restaurants/new"),
    // ]),
    getItem(t("Categories"), "sub4", <CategoryIcon className="icon" />, [
      getItem(t("Categories List"), "/categories"),
      getItem(t("Add Category"), "/categories/new"),
    ]),
    getItem(t("Profile"), "sub5", <AccountCircleOutlinedIcon className="icon" />, [
      getItem(t("Update Profile"), "/users/profile"),
    ]),
    // getItem("Earnings", "/earnings", <MonetizationOnOutlinedIcon />),
    getItem(t("Transactions"), "/transactions", <PaymentsIcon />),
    getItem(t("Logout"), "/logout", <ExitToAppIcon className="icon" />),
  ];
  const onClick = (e) => {
    console.log("click ", e);
    if (e.key !== "/logout") navigate(e.key);
    else signOutUser();
  };
  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <img
            className="cellImg"
            style={{ width: 100, height: 100 }}
            src={require("../../assets/images/logo-100-removed.png")}
            alt="avatar"
          />
        </Link>
      </div>
      <Menu
        onClick={onClick}
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        inlineCollapsed={collapsed}
        items={items}
      />
    </div>
  );
};
export default Sidebar;
