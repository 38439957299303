import i18n from "./translations";
import { decryptData } from "./utils";



export const userColumns = [
  { field: "userId", headerName: "ID", width: 70 },
  {
    field: "user",
    headerName: <b>{i18n.t('User')}</b>,
    width: 230,
    renderCell: (params) => {
      console.log('RRRRRRRRRRRRRRRRRRRRRR', params)
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params?.row?.image ? params?.row?.image : "https://cdn.pixabay.com/photo/2013/07/13/12/07/avatar-159236__340.png"} alt="avatar" />
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: <b>{i18n.t('Email')}</b>,
    width: 250,
  },

  {
    field: "phone",
    headerName: <b>{i18n.t('Phone')}</b>,
    width: 150,
  },
  {
    field: "role",
    headerName: <b>{i18n.t('Role')}</b>,
    width: 100,
  },
  {
    field: "status",
    headerName: <b>{i18n.t('Status')}</b>,
    width: 100,
    renderCell: (params) => {
      console.log('GGGGGGGGGGGGGGGGGGGGGGG', params)
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status}
        </div>
      );
    },
  },
];

export const productColumns = [
  {
    field: "menu",
    headerName: <b>{i18n.t('Menu')}</b>,
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params?.row?.image ? params?.row?.image : "https://cdn.pixabay.com/photo/2013/07/13/12/07/avatar-159236__340.png"} alt="avatar" />
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "price",
    headerName: <b>{i18n.t('Price')}</b>,
    width: 150,
    renderCell: (params) => {
      return (
        <div className="cellWithPrice">
          {params.row.price.toLocaleString('en', {
            style: "currency",
            currency: 'USD'
          })}
        </div>
      );
    },
  },
  {
    field: "offer",
    headerName: <b>{i18n.t('Offer')}</b>,
    width: 230,
    renderCell: (params) => {

      return (
        <div className="cellWithRestaurant">
          {params.row.offer}
          {/* {decryptData(localStorage.getItem(process.env.REACT_APP_RESTAURANTS_KEY)).find(restaurant => restaurant.restaurantId === params.row.restaurantID).name} */}
        </div>
      );
    },
  },

  {
    field: "category",
    headerName: <b>{i18n.t('Category')}</b>,
    width: 230,
    renderCell: (params) => {

      return (
        <div className="cellWithRestaurant">
         {params?.row?.category?.name}
          {/* {decryptData(localStorage.getItem(process.env.REACT_APP_CATEGORIES_KEY)).find(category => category.id === params.row.categorieId).name} */}
        </div>
      );
    },
  },
];

export const restaurantColumns = [
  {
    field: "restaurant",
    headerName: <b>{i18n.t('Restaurant')}</b>,
    width: 250,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.image} alt="avatar" />
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "phone",
    headerName: <b>{i18n.t('Phone')}</b>,
    width: 230,
  },

  {
    field: "address",
    headerName: <b>{i18n.t('Address')}</b>,
    width: 350,
    renderCell: (params) => {
      return (
        <div className="cellWithAddress">
          {params.row.address}
        </div>
      );
    },
  },
];

export const categoryColumns = [
  {
    field: "category",
    headerName: <b>{i18n.t('Category')}</b>,
    width: 250,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params?.row?.image ? params.row.image : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"} alt="avatar" />
        </div>
      );
    },
  },
  {
    field: "name",
    headerName: <b>{i18n.t('Name')}</b>,
    width: 250,
  },
  {
    field: "type",
    headerName: <b>{i18n.t('Type')}</b>,
    width: 300,
    renderCell: (params) => {
      return (
        <div className="cellWithRestaurant">
          {params.row.type ? params.row.type : "restaurant"}
        </div>
      );
    },
  },


];


export const driverColumns = [
  { field: "id", headerName: i18n.t('ID'), width: 70 },
  {
    field: "driver",
    headerName: <b>{i18n.t('Driver')}</b>,
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={"https://cdn.pixabay.com/photo/2013/07/13/12/07/avatar-159236__340.png"} alt="avatar" />
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: <b>{i18n.t('Email')}</b>,
    width: 250,
  },

  {
    field: "phone",
    headerName: <b>{i18n.t('Phone')}</b>,
    width: 150,
  },
  {
    field: "status",
    headerName: <b>{i18n.t('Status')}</b>,
    width: 100,
    renderCell: (params) => {
      return (
        <div className={`cellWithDriverStatus ${params.row.driverStatus}`}>
          {params.row.driverStatus}
        </div>
      );
    },
  },
];

export const orderColumns = [
  {
    field: "orderId",
    headerName: <b>{i18n.t("orders ID")}</b>,
    width: 100,
    renderCell: (params) => params.row.orderId.toUpperCase(),
  },
  {
    field: "customer",
    headerName: <b>{i18n.t("Customer")}</b>,
    width: 180,
    renderCell: (params) => {
      return (
        <div >

          {params.row.User.name}
        </div>
      );
    },
  },
  {
    field: "restaurant",
    headerName: <b>{i18n.t("Restaurant")}</b>,
    width: 230,
    renderCell: (params) => {
      return (
        <div >

          {params.row.Restaurant.name}
        </div>
      );
    },
  },

  {
    field: "amount",
    headerName: <b>{i18n.t("Amount")}</b>,
    width: 150,
    renderCell: (params) => {
      return (
        <div >

          {params.row.User.items.reduce((a, v) => a + v.price, 0).toLocaleString('en', {
            style: "currency",
            currency: 'USD'
          })}
        </div>
      );
    },
  },
  {
    field: "orderType",
    headerName: <b>{i18n.t('Order Type')}</b>,
    width: 100,
  },
  {
    field: "status",
    headerName: <b>{i18n.t('Status')}</b>,
    width: 100,
    renderCell: (params) => {
      return (
        <div className={`cellWithOrderStatus ${params.row.status}`}>
          {params.row.status.toLowerCase()}
        </div>
      );
    },
  },
];

export const restaurantsEarningsColumns = [
  {
    field: "restaurant",
    headerName: <b>{i18n.t("Restaurant")}</b>,
    width: 300,
  },
  {
    field: "earning",
    headerName: <b>{i18n.t("Earning")}</b>,
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithEarning">
          {params.row.earning.toLocaleString('en', {
            style: "currency",
            currency: 'USD'
          })}
        </div>
      );
    },
  },
  {
    field: "adminCommission",
    headerName: <b>{i18n.t("Admin Commission")}</b>,
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithEarning">
          {(params.row.earning * 20 / 100).toLocaleString('en', {
            style: "currency",
            currency: 'USD'
          })}
        </div>
      );
    },
  },
  {
    field: "percentage",
    headerName: <b>{i18n.t('Percentage')}</b>,
    width: 170,
    renderCell: (params) => {
      return (
        <div className="cellWithEarning">
          <span  >20</span>
        </div>
      );
    },
  },

];


const coupons = [
  {
    image: '',
    title: '',
    fixPrice: '',
    percentage: '',
    espireAt: ''
  }
]



