import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns, userRows, productColumns, restaurantColumns, categoryColumns, driverColumns, orderColumns, restaurantsEarningsColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { auth, deleteRestaurant, getCategories, getEarnings, getFoods, getRestaurantsFromFirebase, getUsersFromFirebase } from "../../firebase";
import { decryptData, encryptData } from "../../utils";
import { RestaurantContext } from "../../context/RestaurantContext";
import { DotLoader } from "react-spinners";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Space } from 'antd';
import { del, get } from "../../service/api";
import { useTranslation } from "react-i18next";

const Datatable = ({ type }) => {
  const { confirm } = Modal;
  const { currentRestaurant } = useContext(RestaurantContext)
  const [tab, setTab] = useState({})
  const [title, setTitle] = useState()
  const { t } = useTranslation()
  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Are you sure delete this task?',
      icon: <ExclamationCircleOutlined />,
      content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        console.log('OK', id);
        handleDelete(id)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  const handleDelete = async (id) => {
    if (type === 'restaurants') {
      deleteRestaurant(id).then(() => {
        setTab({
          rows: tab.rows.filter(item => item.id !== id),
          columns: tab.columns
        })
      })
    }
    if (type === 'categories') {
      del(`/categories/delete/${id}`).then(async () => {
        const categories = await get('/categories/get')
        if (categories) {
          setTab({
            rows: categories.data.data.sort((a, b) => b.createdAt - a.createdAt),
            columns: categoryColumns
          })
        }
      })
    }
    if (type === 'products') {
      del(`/products/delete/${id}`).then(async () => {
        const products = await get('/products/get')
        if (products) {
          setTab({
            rows: products.data.data,
            columns: productColumns
          })
        }
      })
    }
    if (type === 'users') {
      del(`/users/delete/${id}`).then(async () => {
        const users = await get('/users/get')
        if (users) {

          setTab({
            rows: users.data.data,
            columns: userColumns
          })
        }
      })
    }
  };
  const actionColumn = [
    {
      field: "action",
      headerName: <b>{t('Action')}</b>,
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {type !== "earnings" ? <Link to={`/${type}/${(((type === "users" || type === "drivers") && params.row.userId) || (type === "restaurants" && params.row.restaurantId)) || params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">{t('View')}</div>
            </Link> : <></>}
            <div
              className="deleteButton"
              onClick={() => showDeleteConfirm(params.row.id)}
            >
              {t('Delete')}
            </div>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    (async () => {
      // let url = ''
      // if (!localStorage.getItem(process.env.REACT_APP_USERS_KEY)){
      //   const { data } = await get(url)
      //   if (data.status == 200) {
      //     if (type == 'users') {
      //       localStorage.setItem(process.env.REACT_APP_USERS_KEY, encryptData(data.data))
      //     }
      //     if (type == 'categories') {
      //       localStorage.setItem(process.env.REACT_APP_CATEGORIES_KEY, encryptData(data.data))
      //     }
      //   }
      // }
      //   url = '/users/get'
      // // await getUsersFromFirebase().then(users => localStorage.setItem(process.env.REACT_APP_USERS_KEY, encryptData(users)))
      // if (!localStorage.getItem(process.env.REACT_APP_CATEGORIES_KEY))
      //   url = '/categories/get'
      // // await getCategories().then(category => localStorage.setItem(process.env.REACT_APP_CATEGORIES_KEY, encryptData(category)))
      // // if (!localStorage.getItem(process.env.REACT_APP_EARNINGS_KEY))
      // // await getEarnings().then(restaurantsEarnings => localStorage.setItem(process.env.REACT_APP_EARNINGS_KEY, encryptData(Object.keys(restaurantsEarnings).map((restaurant, index) => ({ id: index, restaurant: restaurant, earning: restaurantsEarnings[restaurant], adminCommission: restaurantsEarnings[restaurant], percentage: 25 })))))
      // if (url) {
      //   const { data } = await get(url)
      //   if (data.status == 200) {
      //     if (type == 'users') {
      //       localStorage.setItem(process.env.REACT_APP_USERS_KEY, encryptData(data.data))
      //     }
      //     if (type == 'categories') {
      //       localStorage.setItem(process.env.REACT_APP_CATEGORIES_KEY, encryptData(data.data))
      //     }
      //   }
      // }
      switch (type) {
        case "products":
          setTitle("Menu")
          const products = await get('/products/get')
          if (products) {
            setTab({
              rows: products.data.data,
              columns: productColumns
            })
          }
          // if (!localStorage.getItem(process.env.REACT_APP_PRODUCTS_KEY))
          //   getFoods().then(foods => {
          //     localStorage.setItem(process.env.REACT_APP_PRODUCTS_KEY, encryptData(foods)); setTab({
          //       rows: foods.filter(product => product.restaurantID === auth.currentUser?.uid),
          //       columns: productColumns
          //     })
          //   })
          //     .catch((error) => console.log('CCCCCCCCCCCCCCC', error))
          // else {
          //   console.log('GGGGGGGGGGGGGGGGGGGGGGGGGGG', decryptData(localStorage.getItem(process.env.REACT_APP_PRODUCTS_KEY)))
          // }
          break
        case "transactions":
          setTitle("Transactions")
          setTab({
            rows: decryptData(localStorage.getItem(process.env.REACT_APP_ORDERS_KEY)).filter(order => order.status === "Completed"),
            columns: orderColumns
          })
          break
        case "orders":
          setTitle("Orders")
          setTab({
            rows: decryptData(localStorage.getItem(process.env.REACT_APP_ORDERS_KEY)),
            columns: orderColumns
          })
          break
        case "drivers":
          setTitle("Driver")
          setTab({
            rows: decryptData(localStorage.getItem(process.env.REACT_APP_USERS_KEY)).filter(user => user.Role === "driver"),
            columns: driverColumns
          })
          break
        case "restaurants":
          setTitle("Restaurant")
          getRestaurantsFromFirebase().then(restaurants => {
            setTab({
              rows: restaurants,
              columns: restaurantColumns
            })
          })
          break
        case "categories":
          setTitle("Category")
          const categories = await get('/categories/get')
          if (categories) {
            setTab({
              rows: categories.data.data.sort((a, b) => b.createdAt - a.createdAt),
              columns: categoryColumns
            })
          }
          // getCategories().then(categories => {
          // })
          break
        case "earnings":
          setTitle("Earnings")
          console.log(decryptData(localStorage.getItem(process.env.REACT_APP_EARNINGS_KEY)))
          setTab({
            rows: decryptData(localStorage.getItem(process.env.REACT_APP_EARNINGS_KEY)),
            columns: restaurantsEarningsColumns
          })
          break
        default:
          setTitle("User")
          const users = await get('/users/get')
          if (users) {

            setTab({
              rows: users.data.data,
              columns: userColumns
            })
          }
      }
    })();
  }, [])
  if (!tab.columns)
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <DotLoader color={"#36D7B7"} loading={true} size={150} />
    </div>
  console.log('EEEEEEEEEEEEEEEEEEEEEE', tab.rows, tab.columns)
  return (
    <div className="datatable">
      <div className="datatableTitle">
        {type === "earnings" || type === "orders" ? null : "Add New"} {t(title)}
        {type === "earnings" || type === "orders" ? null : <Link to={`/${type}/new`} className="link">
          {t('Add New')}
        </Link>}
      </div>
      <DataGrid
        className="datagrid"
        rows={tab.rows}
        columns={tab.columns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};
export default Datatable;
