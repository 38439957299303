import axios from "axios";


const axiosInstanse = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

export const post = (url, body, headers) => axiosInstanse.post(url, body, headers)

export const get = (url, headers) => axiosInstanse.get(url, headers)

export const put = (url, body, headers) => axiosInstanse.put(url, body, headers)

export const patch = (url, body, headers) => axiosInstanse.patch(url, body, headers)

export const del = (url, body) => axiosInstanse.delete(url, body)